<template>
  <div
    key="navMenuAuth"
    class="NavMenuAuth"
  >
    <el-header>
      <el-row>
        <el-col
          :span="22"
          :offset="1"
          class="headline"
        >
          <router-link
            to="/"
            class="logo"
          >
            <app-logo />
            <span class="logo-text">Timeragent</span>
          </router-link>
          <el-menu
            :router="true"
            :default-active="$route.path"
            class="el-menu-demo"
            mode="horizontal"
            @select="startLoading"
          >
            <el-menu-item
              :index="generateUrl('/dashboard')"
            >
              <router-link
                :to="generateUrl('/dashboard')"
              >
                <span>
                  Dashboard
                </span>
              </router-link>
            </el-menu-item>
            <el-menu-item :index="generateUrl('/tracker')">
              <router-link :to="generateUrl('/tracker')">
                <span>
                  Tracker
                </span>
              </router-link>
            </el-menu-item>

            <el-submenu
              index="3"
            >
              <template
                slot="title"
              >
                Planning
              </template>
              <el-menu-item
                :index="generateUrl('/poker-sessions')"
              >
                <router-link :to="generateUrl('/poker-sessions')">
                  <span>
                    Poker
                  </span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                :index="generateUrl('/kanban')"
              >
                <router-link :to="generateUrl('/kanban')">
                  <span>
                    Kanban
                  </span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                :index="generateUrl('/sprints')"
                disabled
              >
                Sprints
              </el-menu-item>
            </el-submenu>

            <el-menu-item
              :index="generateUrl('/calendar')"
            >
              <router-link :to="generateUrl('/calendar')">
                <span>
                  Calendar
                </span>
              </router-link>
            </el-menu-item>

            <el-submenu
              index="2"
            >
              <template
                slot="title"
              >
                Accounting
              </template>
              <el-menu-item
                :index="generateUrl('/invoices')"
              >
                Invoices
              </el-menu-item>
              <el-menu-item
                :index="generateUrl('/budgets')"
                disabled
              >
                Budgets
              </el-menu-item>
            </el-submenu>

            <el-menu-item
              :index="generateUrl('/reports')"
            >
              <router-link :to="generateUrl('/reports')">
                <span>
                  Reports
                </span>
              </router-link>
            </el-menu-item>

            <el-submenu
              index="4"
              class="nav-submenu"
            >
              <template slot="title">
                <span>
                  {{ organization.name }}
                </span>
              </template>
              <div
                v-if="menuOrganizations.length"
                class="submenu-area--title gray-text"
              >
                Workspaces
              </div>
              <div
                v-for="organization in menuOrganizations"
                :key="organization.uuid"
                class="submenu-area--body"
              >
                <el-menu-item :index="`/${organization.uuid}/dashboard`">
                  <router-link
                    :to="{
                      name: 'dashboardPage',
                      params: {
                        organizationUuid: organization.uuid
                      }
                    }"
                  >
                    {{ organization.name }}
                  </router-link>
                </el-menu-item>
              </div>
              <div class="submenu-area--title gray-text">
                Manage
              </div>
              <div class="submenu-area--body">
                <el-menu-item
                  :index="generateUrl('/teams')"
                >
                  <router-link :to="generateUrl('/teams')">
                    <t-icon
                      :icon="['fas', 'users-cog']"
                      class="nav-icon"
                      fixed-width
                    />
                    Teams
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  :index="generateUrl('/clients')"
                >
                  <router-link :to="generateUrl('/clients')">
                    <t-icon
                      :icon="['fas', 'user-tie']"
                      class="nav-icon"
                      fixed-width
                    />
                    Clients
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  :index="generateUrl('/projects')"
                >
                  <router-link :to="generateUrl('/projects')">
                    <t-icon
                      :icon="['fas', 'cabinet-filing']"
                      class="nav-icon"
                      fixed-width
                    />
                    Projects
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  :index="generateUrl('/members')"
                >
                  <router-link :to="generateUrl('/members')">
                    <t-icon
                      :icon="['fas', 'user-hard-hat']"
                      class="nav-icon"
                      fixed-width
                    />
                    Employees
                  </router-link>
                </el-menu-item>
              </div>
              <div class="submenu-area--title gray-text">
                Settings
              </div>
              <div class="submenu-area--body">
                <el-menu-item :index="generateUrl('/organizations/new')">
                  <router-link :to="generateUrl('/organizations/new')">
                    <i class="el-icon-plus" />
                    Add Organization
                  </router-link>
                </el-menu-item>
                <el-menu-item :index="generateUrl('/edit')">
                  <router-link :to="generateUrl('/edit')">
                    My Organization
                  </router-link>
                </el-menu-item>
                <el-menu-item :index="generateUrl('/profile')">
                  <router-link :to="generateUrl('/profile')">
                    My Profile
                  </router-link>
                </el-menu-item>
                <el-menu-item
                  disabled
                  :index="generateUrl('/help')"
                >
                  <t-icon
                    :icon="['fas', 'life-ring']"
                    class="nav-icon"
                    fixed-width
                  />
                  Help
                </el-menu-item>
                <el-menu-item
                  :index="generateUrl('/login')"
                  @click="logout"
                >
                  <router-link :to="generateUrl('/login')">
                    <span>Log out</span>
                  </router-link>
                </el-menu-item>
              </div>
            </el-submenu>
          </el-menu>
        </el-col>
      </el-row>
    </el-header>
  </div>
</template>

<script>
import ElRow from 'element-ui/packages/row/src/row';
import moment from 'moment';
import { mapGetters } from 'vuex';

import loading from '../../mixins/loading';

export default {
  components: {
    ElRow,
    AppLogo: () => import('@/components/blocks/AppLogo.vue'),
  },
  mixins: [loading],
  data() {
    return {
      isOpened: null,
      users: '',
    };
  },
  computed: {
    ...mapGetters([
      'organization',
      'user',
      'isAlpha',
    ]),
    date() {
      return moment().format('YYYY-MM-DD');
    },
    menuOrganizations() {
      return this.user.organizations
        .filter(organization => organization.uuid !== (this.organization).uuid);
    },
  },
  methods: {
    showSubMenu(name) {
      this.isOpened = (this.isOpened === null) ? name : null;
    },
    generateUrl(url) {
      if (url === '/organizations/new') {
        return `${url}`;
      }
      if (url === '/profile') {
        return `${url}`;
      }

      return `/${this.organization.uuid}${url}`;
    },
    logout() {
      localStorage.clear();
      this.user.organizations = [];
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style
  lang="stylus"
  rel="stylesheet/stylus"
  scoped
>

  .el-badge::v-deep .el-badge__content {
    top: 3px;
  }

  .el-menu-item a {
    display: block;
    width: 100%;
    color: #525252;
  }

  .control-buttons {
    padding: 17px;
  }

  .el-header {
    background-color: #fff;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .submenu-area--title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 13px;
    padding: 2px 4px;
  }

  .submenu-area--body {
    .el-menu-item {
      padding: 0 20px !important;
    }
  }

  body {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 1.42857;
    color: #525252;

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      min-width: 160px;
      padding: 6px 0;
      margin: 5px 0 0;
      list-style: none;
      font-size: 14px;
      text-align: left;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 3px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
      background-clip: padding-box;
    }

    .btn {
      font-size: 18px;
    }

    header {

      display: block;
      flex-grow: 0;
      /*background-color : #464646;*/
      box-shadow: 0 2px 3px 0 rgba(47, 47, 47, .25) !important;

      .fa {
        font-size: 0.9em;
      }

      .navbar {
        display: flex;
        justify-content: space-between;
        margin: auto !important;
        border: 0 !important;
        border-radius: unset;

        img {
          vertical-align: middle;
        }

        .dropdown {

          .dropdown-menu {

            position: absolute;
            left: 0;
            z-index: 1000;
            display: none;
            min-width: 160px;
            padding: 6px 0;
            margin: 5px 0 0;
            list-style: none;
            font-size: 14px;
            text-align: left;
            border-radius: 3px;
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            background-clip: padding-box;
            background-color: #393939;
            border: none;
            top: 65px;
            box-shadow: 2px 2px 3px 0 rgba(47, 47, 47, .25) !important;

            li {

              a {
                color: rgba(255, 255, 255, .5);
                display: block;
                padding: 6px 25px;
                font-weight: 400;
              }

              a:hover {
                color: rgba(255, 255, 255, .75);
                background-color: #444;
              }
            }

          }

          .open {
            display: block;

            a {
              background-color: #393939;
              color: #fff;
            }
          }

        }

        * {
          display: inline-block;
          vertical-align: top;
          margin: 0;
          line-height: inherit;
        }

        .timer-buttons {

          .btn {
            height: 48px;
            width: 48px;
            min-width: 0;
            margin: 11px 3px;
            border-radius: 48px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .2) !important;

            color: #fff;
            background-color: #00bc6a;
          }

          .btn-timer-start:hover {
            background-color: #00a35c;
            border-color: #00a35c;
          }

          .btn-timer-continue:hover {
            background-color: #00a35c;
            border-color: #00a35c;
          }

          .btn-timer-stop {
            height: 36px;
            width: 36px;
            margin: 17px 3px;
            border-radius: 36px;
            padding: 6px 0;

            color: #fff;
            background-color: #e26a6a;
          }

          .btn-timer-stop:hover {
            background-color: #de5555;
            border-color: #de5555;
          }
        }

      }

      .navbar-default {

        background-color: #464646;

        .navbar-nav {

          a.router-link-active {
            color: #fff;
            background-color: #393939;
            border-top: 4px solid transparent;
            border-bottom: 4px solid #fff;
            padding-top: 21px;
            padding-bottom: 21px;
          }

          a.router-link-active:hover {
            color: #fff;
          }

          .router-link-active {

            a {
              color: #fff;
              background-color: #393939;
              border-top: 4px solid transparent;
              border-bottom: 4px solid #fff;
              padding-top: 21px;
              padding-bottom: 21px;
            }

            .open {
              a.router-link-active {
                color: #fff;
                background-color: #393939;
              }

              a.router-link-active:hover {
                color: #fff;
              }

              a {
                display: block;
                padding: 6px 25px;
                font-weight: 400;
                color: rgba(255, 255, 255, .5);
                border: none;
              }
            }
          }

          .open a {
            display: block;
            padding: 6px 25px;
            font-weight: 400;
            color: rgba(255, 255, 255, .5);

          }

          /*.open {*/
          /*a.router-link-active {*/
          /*color: #fff;*/
          /*background-color: #393939;*/
          /*}*/
          /*a.router-link-active:hover {*/
          /*color: #fff;*/
          /*}*/
          /*}*/

          a.expanded {
            background-color: #393939;
            color: #fff;
          }

          a:focus {
            color: rgba(255, 255, 255, .75);
            background-color: #404040;
          }

          li {

            a {
              color: rgba(255, 255, 255, .5);
              font-weight: bold;
              max-height: 70px;
              padding-top: 25px;
              padding-bottom: 25px;
            }
          }
        }
      }
    }
  }

  .nav-submenu::v-deep div.el-submenu__title {
    padding-right: 0;
  }
</style>

<style>
  .headline {
    display               : grid;
    grid-template-columns : min-content auto;
    align-items           : start;
  }

  .logo {
    display               : grid;
    grid-template-columns : 30px min-content;
    align-items           : center;
    grid-gap              : 3px;
    margin-top            : 10px;
  }

  .logo-text {
    font-family    : 'Advent Pro', cursive;
    font-size      : 28px;
    color          : #409eff;
    letter-spacing : 1px;
  }

  .el-menu-demo {
    justify-self : end;
  }

  .nav-icon {
    margin-right : 5px;
  }
</style>
